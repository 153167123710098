import moment_ from "moment";

export let models = {
	user: {
		'pseudo': '',
		'avatar': '',

		'creationDate': moment_(),
		'birthDate': moment_(),

		'firstName': '',
		'lastName': '',

		'discord': {
			'id': '',
			'user': '',
			'username': '',
			'avatar': ''
		},

		'companion': '',

		'main': '',

		'ranks': [],
		'suspended': {}
	},

	player: {
		'pseudo': '',
		'uuid': '20beafd1912c4c05bb8f3130f82248a2',
		'id': '',

		'owner': '',

		'creationDate': moment_(),
		'birthDate': moment_(),
		'age': '',

		'firstName': '',
		'lastName': 'NOM?',

		'address': {
			'street': '',
			'city': ''
		},

		'gender': '',

		'bio': '',

		'status': {
			'law': false,
			'suspended': {
				'is': false,
				'date': '',
				'reason': ''
			},
			'dead': {
				'is': false,
				'date': ''
			}
		}
	},

	news: {
		'content': '',
		'author': '',
		'creationDate': moment_(),
	},

	company: {
		'name': '',
		'owner': '',
		'address': {
			'street': '',
			'city': ''
		},
		'wage': {
			'value': '',
			'category': ''
		},
		'config': {
			'hexcolor': ''
		},
		'economy': {
			'benefits': 0
		},
		'stats': {
			'orders': 0,
			'uniqueOrders': 0
		},

		'mainRole': 0,

		'creationDate': moment_(),

		'active': true
	},

	rank: {
		color: "#000000",
		name: "",
		adminAccess: false
	},

	vehicle: {
		'call': {
			'on_call': '',
			'occupant': ''
		},
		'location': '',
		'status': 1,
		'name': '',
		'type': ''
	},

	service: {
		'name': '',
		'tarhor': 0,
		'webhook': {
			'icon': '',
			'link': {
				'id': '',
				'name': '',
				'token': ''
			}
		},
		'stats': {
			'reports': 0,
			'calls': 0
		},
		'hierarchy': {},
		'label': '',
		'style': {
			'background': '',
			'icon': ''
		}
	},

	emergencyDetails: {
		'caller': '',
		'date': moment_(),
		'emergency': '',
		'location': '',
		'status': 0
	},

	license: {
		'addDate': moment_(),
		'category': 0,
		'decernedBy': '',
		'type': '',
		'owner': {
			'lastName': ''
		}
	},

	report: {
		'index': 0,
		'status': 0,
		'type': '',
		'creaDate': moment_(),
		'callDetails': {},
		'address': '',
		'summary': '',
		'suspect': '',
		'degree': 0
	},

	order: {
		'buyDate': moment_(),
		'buyer': {},
		'compName': '',
		'details': '',
		'index': 0,
		'nOrder': 0,
		'status': 0,
		'value': 0,
		'price': 0
	},

	invoice: {
		creaDate: new Date(),
		index: String(),
		indexNo: Number(),
		receiver: String(),
		receiverType: String(),
		sender: String(),
		senderType: String(),
		status: Number(),
		statusDate: new Date(),
		title: String(),
		value: Number()
	},

	mail: {
		id: String(),

		acknowledge: {
			state: Boolean(),
			date: moment_(),
		},
		deleted: Boolean(),

		author: String(),
		authorInfo: {
			label: "Chargement..."
		},
		trueAuthor: String(),
		emailAddress: String(),

		receiver: String(),
		receiverInfo: {
			label: "Chargement..."
		},

		subject: String(),
		content: String(),


		sendDate: moment_(),
	}
};
