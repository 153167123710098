let remoteConfig = {};

export function initRemoteConfig(Vue, firebase) {
	const remoteConfig = firebase.remoteConfig();

	remoteConfig.settings.minimumFetchIntervalMillis = 30000;

	Vue.prototype.$remoteConfig = remoteConfig;

	reloadRemoteConfig(remoteConfig);
}

export function reloadRemoteConfig(remoteConfig) {
	remoteConfig.fetchAndActivate()
		.then(() => {
			console.log("[Firebase] RemoteConfig updated !");
		})
		.catch((err) => {
			console.log("[Firebase] An error occured while retrieving the remote configuration!", err);
		});
}