<template>
	<div>
		<card class="p-0 m-0">
			<div class="row">
				<div class="col">
					<h3>{{ denom }} n°{{ report.index }}</h3>
				</div>

				<div class="col-auto">
					<button type="button" class="btn btn-sm" @click="modals.display = true"><i class="far fa-eye text-primary"></i></button>
					<router-link :to="'/report/' + serviceInfo.label + '/' + report.id">
						<button type="button" class="btn btn-sm"><i class="fa-solid fa-arrow-up-right-from-square text-primary"></i></button>
					</router-link>
				</div>
			</div>

			<small class="text-muted">
				<i class="far fa-clipboard"></i> {{ report.type.charAt(0).toUpperCase() + report.type.slice(1).replace("_", " ") }}<br/>
				<template v-if="viewBrief && report.summary">
					<i class="far fa-sticky-note" aria-hidden="true"></i> {{ report.summary }}<br />
				</template>
				<i class="far fa-clock" aria-hidden="true"></i> Le {{ $moment(report.creaDate.toDate()).format("DD/MM/YYYY à HH:mm") }}
			</small>
		</card>

		<hr class="mb-3 mt-3" v-show="!displayNicer">

		<modal :show.sync="modals.display" modal-classes="modal-dialog-centered modal-xl">
			<h4 slot="header" class="modal-title">{{ denom }} n°{{ report.index }}</h4>

			<div class="row mt--4 mb--3">
				<div class="col-12 col-lg-4">

					<card :gradient="serviceInfo.style.color">

						<div class="row text-center">
							<!--<span
								@click="postAs = 'firefighter'"
								v-if="authorizations.firefighter"
							><span class="shortcut-media avatar rounded-circle bg-gradient-red mx-3 my-1"><i class="fa fa-fire"></i></span> Pompiers</span>-->

							<div class="col-12">
								<i :class="`fa fa-${serviceInfo.style.icon} text-white fa-xl`"></i>
								<h3 class="text-white mt-3">État de Wetston — {{ serviceInfo.name }}</h3>

								<hr
									style="border-top-color: white;"
									class="mt-4"
								>
							</div>

							<div class="col-12">
								<div class="row">
									<div class="col text-left">
										<span class="h5 surtitle text-bold text-white">
											<i class="far fa-edit"></i> Rédacteur
										</span>
									</div>
									<div class="col-auto">
										<h4 class="text-white">{{ redactor.firstName[0] }}. {{ redactor.lastName.toUpperCase() }}</h4>
									</div>
								</div>
							</div>

							<div class="col-12">
								<div class="row">
									<div class="col text-left">
										<span class="h5 surtitle text-bold text-white">
											<i class="far fa-clipboard"></i> Type
										</span>
									</div>
									<div class="col-auto">
										<h4 class="text-white">{{ report.type.charAt(0).toUpperCase() + report.type.slice(1).replace("_", " ") }}</h4>
									</div>
								</div>
							</div>

							<div class="col-12">
								<div class="row">
									<div class="col text-left">
										<span class="h5 surtitle text-bold text-white">
											<i class="far fa-clock"></i> Horodatage
										</span>
									</div>
									<div class="col-auto">
										<h4 class="text-white">{{ $moment(report.creaDate.toDate()).format("DD/MM/YYYY HH:mm") }}</h4>
									</div>
								</div>
							</div>

							<div
								class="col-12"
								v-if="report.callDetails">
								<div class="row">
									<div class="col text-left">
										<span class="h5 surtitle text-bold text-white">
											<i class="fa fa-tower-broadcast"></i> Intervention
										</span>
									</div>
									<div class="col-auto">
										<h4 class="text-white">{{ $moment(report.callDetails.date.toDate()).format("DD/MM/YYYY HH:mm") }}</h4>
									</div>
								</div>
							</div>


						</div>

					</card>

				</div>
				<div class="col">

					<div class="row">

						<div v-if="report.summary" class="col">
							<span class="h5 surtitle text-bold">
								<i class="far fa-file"></i> Résumé
							</span>
							<div class="h4">{{ report.summary }}</div>
						</div>

						<div
							class="col"
							v-if="report.address"
							v-bind:class="{'col-4': report.summary}"
						>
							<span class="h5 surtitle text-bold">
								<i
									class="far fa-map"
									aria-hidden="true"
								></i> Lieu
							</span>
							<div class="h4">{{ report.address }}</div>
						</div>

					</div>

					<hr
						class="mt-2 mb-3"
						v-if="report.address || report.summary || report.callDetails">

					<div class="row">
						<div class="col">

							<div>
								<span class="h5 surtitle text-bold">
									<i class="far fa-file-alt"></i> Détails
								</span>
								<div
									class="h4"
									v-html="report.details.substring(0, 300).trim() + '...'"
									v-if="report.details.length > 300"
								></div>
								<div
									class="h4"
									v-html="report.details"
									v-else
								></div>
							</div>
						</div>

						<div class="col-4" v-if="type.toUpperCase() == 'POLICE'">
							<div>
								<span class="h5 surtitle text-bold">
									<i class="fas fa-crosshairs"></i> Position
								</span>
								<div class="h4" v-if="report.suspect == playerInfo.id">Suspect</div>
								<div class="h4" v-else>Victime</div>
							</div>
							<div>
								<span class="h5 surtitle text-bold">
									<i class="far fa-flag"></i> Degré
								</span>
								<div class="h4">{{ report.degree }}</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			
		</modal>
	</div>
</template>

<script>
	export default {
		name: "PublicReport",
		props: {
			'report': {
				type: Object
			},
			'displayNicer': {
				type: Boolean,
				default: false
			},
			'type': {
				type: String
			},
			'playerInfo': {
				type: Object
			},
			'viewBrief': {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				denom: '',
				idService: '',
				service: {
					"id": ""
				},
				serviceType: '',

				modals: {
					display: false
				},

				redactor: this.$models.player,
				serviceInfo: this.$models.service
			}
		},
		async mounted() {
			let serviceID;

			switch(this.type.toUpperCase()){
				case "FIREFIGHTER":
				case "POMPIER":
					this.denom = "Fiche";

					serviceID = "e3LYFzTIZlQpRE7XGv3p";
				break;
				case "POLICE":
					this.denom = "Affaire";

					serviceID = "yDSIoH2qArdxv4Z00QBk";
				break;
			}

			this.$db.collection("players").doc(this.report.creator).get().then((docp) => {
				this.redactor = docp.data();
			});

			let tempSI = await this.$db.collection("public_services").doc(serviceID).get();
			this.serviceInfo = {
				...tempSI.data(),
				id: tempSI.id
			};
		}
	}
</script>

<style>

</style>
