import firebase from "firebase/app";
import db from "../../modules/firebase/firebaseInit";
import "firebase/auth";

import moment from "moment";

export let functions = {
	getPlayers: function (type = "players", order = "asc") {
		db.collection("players").orderBy("lastName", order).onSnapshot((res) => {
			var playerList = [];
			var players = [];

			res.forEach((document) => {
				var data = document.data();

				console.log(data);

				playerList.push({
					label: data.lastName.toUpperCase() + " " + data.firstName,
					value: document.id
				});

				players.push({
					...data,
					id: document.id
				});

				return "cc";

				switch (type.toUpperCase()) {
					case 'PLAYERLIST':
						return playerList;

					default:
						return players;
				}
			});
		});
	},

	getInterval: function (id, now, then) {
		var ms = moment(new Date).diff(moment(then));
		return moment(ms).subtract(1, 'hours').format("HH:mm:ss");
	},
	
	randomString: function (length) {
		let text = "";
		let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

		for (let i = 0; i < length; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
	},

	findWithAttr: function(array, attr, value) {
		for (var i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	},
};
