import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

//const useComponent = component => () => import(`./components/pages/${component}.vue`);

// Charts
const Charts = () => import( /* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () => import( /* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () => import( /* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () => import( /* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () => import( /* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () => import( /* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () => import( /* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const Dashboard = () => import( /* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const Widgets = () => import( /* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');
const Dashboard_Admin = () => import( /* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard_Admin.vue');
const Dashboard_Company = () => import( /* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard_Company.vue');
const Dashboard_PublicServices = () => import( /* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard_PublicServices.vue');

// Forms pages
const FormElements = () => import( /* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const FormComponents = () => import( /* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () => import( /* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () => import( /* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () => import( /* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const UserCharacter = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/UserCharacter.vue');
const UserOptions = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/UserOptions.vue');
const UserProfile = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');

const Player = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Player.vue');

const Login = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Register = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');

const CompanyOrders = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Company/Orders.vue');
const CompanyHR = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Company/HR.vue');
const CompanyCompta = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Company/Compatibility.vue');
const CompanyLicenses = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Company/Licenses.vue');
const CompanyLicenseplates = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Company/LicensePlates.vue');

const AdminCreation = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Administration/Creation.vue');
const AdminSandbox = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Administration/Sandbox.vue');
const AdminUsers = () => import( /* webpackChunkName: "pages" */ '@/views/Pages/Administration/Users.vue');

const Blank = () => import( /* webpackChunkName: "pages" */ '@/views/Layout/Blank.vue');

// TableList pages
const RegularTables = () => import( /* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () => import( /* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () => import( /* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');
let componentsMenu = {
	path: '/components',
	component: DashboardLayout,
	redirect: '/components/buttons',
	name: 'Components',
	children: [{
			path: 'buttons',
			name: 'Buttons',
			component: Buttons
		},
		{
			path: 'cards',
			name: 'Cards',
			component: Cards
		},
		{
			path: 'grid-system',
			name: 'Grid System',
			component: GridSystem
		},
		{
			path: 'notifications',
			name: 'Notifications',
			component: Notifications
		},
		{
			path: 'icons',
			name: 'Icons',
			component: Icons
		},
		{
			path: 'typography',
			name: 'Typography',
			component: Typography
		}
	]
};
let formsMenu = {
	path: '/forms',
	component: DashboardLayout,
	redirect: '/forms/elements',
	name: 'Forms',
	children: [{
			path: 'elements',
			name: 'Form elements',
			component: FormElements
		},
		{
			path: 'components',
			name: 'Form components',
			component: FormComponents
		},
		{
			path: 'validation',
			name: 'Form validation',
			component: FormValidation
		}
	]
};

let tablesMenu = {
	path: '/tables',
	component: DashboardLayout,
	redirect: '/table/regular',
	name: 'Tables menu',
	children: [{
			path: 'regular',
			name: 'Tables',
			component: RegularTables
		},
		{
			path: 'sortable',
			name: 'Sortable',
			component: SortableTables
		},
		{
			path: 'paginated',
			name: 'Paginated Tables',
			component: PaginatedTables
		}
	]
};

let mapsMenu = {
	path: '/maps',
	component: DashboardLayout,
	name: 'Maps',
	redirect: '/maps/google',
	children: [{
			path: 'google',
			name: 'Google Maps',
			component: GoogleMaps
		},
		{
			path: 'vector',
			name: 'Vector Map',
			component: VectorMaps
		}
	]
};

let adminMenu = {
	path: '/administration',
	component: DashboardLayout,
	name: 'Administration',
	redirect: '/administration/user',
	children: [
		{
			path: 'dashboard',
			name: 'Tableau de bord',
			component: Dashboard_Admin,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: 'creation',
			name: 'Création',
			component: AdminCreation,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: 'sandbox',
			name: 'Sandbox',
			component: AdminSandbox,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: 'users',
			name: 'Utilisateurs',
			component: AdminUsers,
			meta: {
				requiresAuth: true
			}
		}
	]
};

let authPages = {
	path: '/',
	component: AuthLayout,
	name: 'Authentication',
	children: [{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/register',
			name: 'Register',
			component: Register
		},
		{
			path: '/lock',
			name: 'Lock',
			component: Lock,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '*',
			component: NotFound
		}
	]
};

const routes = [{
		path: '/',
		redirect: '/login',
		name: 'Home'
	},
	componentsMenu,
	formsMenu,
	tablesMenu,
	mapsMenu,
	adminMenu,
	{
		path: '/',
		component: DashboardLayout,
		redirect: '/dashboard',
		name: 'Accueil',
		children: [{
				path: 'dashboard',
				name: 'Dashboard',
				component: Dashboard,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'charts',
				name: 'Charts',
				component: Charts
			},
			{
				path: 'widgets',
				name: 'Widgets',
				component: Widgets
			},

			// ## MES ROUTES LÀ
			{
				path: 'character',
				name: 'Personnage',
				component: UserCharacter,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'options',
				name: 'Options',
				component: UserOptions,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'profile',
				name: 'Profil',
				component: UserProfile,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'blank',
				name: 'Blank',
				component: Blank,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: "/twitter",
				name: 'Twitter',
				component: () => import(`@/views/Pages/Twitter.vue`),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: "/mails",
				name: 'Mails',
				component: () => import(`@/views/Pages/Mails.vue`),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: "/mails/:id",
				name: 'SingleMail',
				component: () => import(`@/views/Pages/Mails.vue`),
				props: true,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: "/invoices",
				name: 'Factures Self',
				component: () => import(`@/views/Pages/Invoices.vue`),
				meta: {
					requiresAuth: true
				},
				props: true
			},
			{
				path: "/company/:owner/invoices",
				name: 'Factures Companies',
				component: () => import(`@/views/Pages/Invoices.vue`),
				meta: {
					requiresAuth: true
				},
				props: true
			}, {
				path: "/department/:owner/invoices",
				name: 'Factures SP',
				component: () => import(`@/views/Pages/Invoices.vue`),
				meta: {
					requiresAuth: true
				},
				props: true
			},

			// SERVICES PUBLICS
			{
				path: "/department",
				name: 'Tableau de bord Services Publics',
				component: Dashboard_PublicServices,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: "/cad/5tUnon3S4uWi40bPfON4",
				name: 'CAD Gouvernement',
				component: () => import(`@/views/Dashboard/Dashboard_Government.vue`),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: "/cad/:serviceID",
				name: 'CAD Services Publics',
				component: () => import(`@/views/Dashboard/Dashboard_CAD.vue`),
				meta: {
					requiresAuth: true
				},
				props: true
			},

			// GOUVERNEMENT
			{
				path: "/gov/cadastres",
				name: 'Cadastres',
				component: () => import(`@/views/Pages/Government/Cadastres.vue`),
				meta: {
					requiresAuth: true
				},
				props: true
			},

			// PROFIL RP
			{
				path: "/report/:type/:id",
				name: 'Rapport',
				component: () => import(`@/views/Pages/Report.vue`),
				meta: {
					requiresAuth: true
				},
				props: true
			},
			{
				path: "/citizen/:id",
				name: 'Joueur',
				component: Player,
				meta: {
					requiresAuth: true
				},
				props: true
			},

			// ENTREPRISES
			{
				path: "/company/:id",
				name: 'Entreprise',
				component: Dashboard_Company,
				meta: {
					requiresAuth: true
				},
				props: true
			},
			{
				path: "/company/:id/orders",
				name: 'Entreprise • Commandes',
				component: CompanyOrders,
				meta: {
					requiresAuth: true
				},
				props: true
			},
			{
				path: "/company/:id/hr",
				name: 'Entreprise • Ressources humaines',
				component: CompanyHR,
				meta: {
					requiresAuth: true
				},
				props: true
			},
			{
				path: "/company/:id/compatibility",
				name: 'Entreprise • Comptabilité',
				component: CompanyCompta,
				meta: {
					requiresAuth: true
				},
				props: true
			},
			{
				path: "/company/:id/licenses",
				name: 'Entreprise • Gestion Permis',
				component: CompanyLicenses,
				meta: {
					requiresAuth: true
				},
				props: true
			},
			{
				path: "/company/:id/siv",
				name: 'Entreprise • SIV',
				component: CompanyLicenseplates,
				meta: {
					requiresAuth: true
				},
				props: true
			},
		]
	},
	authPages,
];

export default routes;
