<template>
	<div class="wrapper">
		<notifications></notifications>
		<side-bar>
			<template slot="links" v-if="!this.playerInfo.status.suspended.is && !this.playerInfo.status.dead.is && !this.userInfo.suspended.is">

				<sidebar-item :link="{ name: 'Tableau de bord', path: '/dashboard', icon: 'ni ni-tv-2 text-primary' }"></sidebar-item>

				<sidebar-item v-if="companies.length > 0" :link="{name: 'Mes entreprises', icon: 'ni ni-building text-primary',}">
					<div v-for="company in companies" :key="company.id">
						<sidebar-item :link="{ 'name': company.name, 'path': `/company/${company.id}` }"></sidebar-item>
					</div>
				</sidebar-item>

				<sidebar-item v-if="activeCompany.name" :link="{name: activeCompany.name, icon: 'ni ni-briefcase-24 text-primary'}">
					<sidebar-item :link="{ 'name': 'Tableau de bord', 'path': `/company/${activeCompany.id}` }" />
					<sidebar-item :link="{ 'name': 'Commandes', 'path': `/company/${activeCompany.id}/orders` }" />
					<sidebar-item :link="{ 'name': 'Factures', 'path': `/company/${activeCompany.id}/invoices` }" />
					<sidebar-item v-if="activeCompany.mainRole == 1 || activeCompany.mainRole == 2 || activeCompany.mainRole == 3" :link="{ 'name': 'Gestion Permis', 'path': `/company/${activeCompany.id}/licenses` }" />
					<sidebar-item v-if="activeCompany.mainRole == 1" :link="{ 'name': 'SIV', 'path': `/company/${activeCompany.id}/siv` }" />
					<sidebar-item v-if="activeCompany.owner == playerInfo.id" :link="{ 'name': 'Ressources Humaines', 'path': `/company/${activeCompany.id}/hr` }" />
					<sidebar-item :link="{ 'name': 'Comptabilité', 'path': `/company/${activeCompany.id}/compatibility` }" />
				</sidebar-item>

				<sidebar-item :link="{ name: 'Mes informations', path: '/profile', icon: 'fa fa-user text-primary' }"></sidebar-item>

				<sidebar-item :link="{ name: 'Mes factures', path: '/invoices', icon: 'fas fa-file-invoice text-primary' }"></sidebar-item>

				<sidebar-item
					v-if="$remoteConfig.getBoolean('feature_mails') || isBeta"
					:link="{ name: 'Mes mails', path: '/mails', icon: 'fas fa-envelopes-bulk text-primary' }"
				></sidebar-item>

				<template v-if="authorizations.police || authorizations.firefighter">
					<hr class="my-1">

					<sidebar-item :link="{ name: 'Services publics', path: '/department', icon: 'ni ni-single-copy-04 text-default' }"></sidebar-item>
					<sidebar-item :link="{name: 'Citoyens', icon: 'ni ni-folder-17 text-default',}">
						<div v-for="player in players" :key="player.value">
							<sidebar-item :link="{ 'name': player.label, 'path': `/citizen/${player.value}` }"></sidebar-item>
						</div>
					</sidebar-item>
				</template>

				<template v-if="authorizations.gov">
					<hr class="my-1">

					<sidebar-item :link="{ name: 'Gouvernement', icon: 'fa fa-university text-black' }">
						<sidebar-item :link="{ 'name': 'Justice', 'path': `/gov/justice` }"></sidebar-item>
						<sidebar-item :link="{ 'name': 'Cadastres', 'path': `/gov/cadastres` }"></sidebar-item>
						<sidebar-item :link="{ 'name': 'Testaments', 'path': `/gov/testaments` }"></sidebar-item>
						<sidebar-item :link="{ 'name': 'Taxes', 'path': `/gov/taxes` }"></sidebar-item>
					</sidebar-item>
				</template>
				
				<hr class="my-1">

				<!--<sidebar-item :link="{ name: 'WMail', path: '/wmail', icon: 'ni ni-email-83 text-primaryLegacy' }"></sidebar-item>-->

				<sidebar-item :link="{ name: 'Twitter', path: '/twitter', icon: 'fab fa-twitter text-info' }"></sidebar-item>
				<!--<sidebar-item :link="{ name: 'TripAdvisor', path: '/tripadvisor', icon: 'fab fa-tripadvisor text-success' }"></sidebar-item>-->
			</template>

			<template slot="links-after" v-else>
				<h6 class="navbar-heading p-0 text-muted text-center">Actions limitées</h6>

				<ul class="navbar-nav mb-md-3">
					<!--<li class="nav-item" v-if="!this.playerInfo.status.suspended.is">
						<a class="nav-link" href="#/register">
							<i class="ni ni-check-bold"></i>
							<span class="nav-link-text">Nouveau personnage</span>
						</a>
					</li>-->

					<li class="nav-item">
						<a class="nav-link" href="#/character">
							<i class="ni ni-single-02"></i>
							<span class="nav-link-text">Mon profil</span>
						</a>
					</li>

					<li class="nav-item">
						<a class="nav-link" href="#/options">
							<i class="ni ni-settings-gear-65"></i>
							<span class="nav-link-text">Paramètres</span>
						</a>
					</li>

					<li
						class="nav-item nav-link"
						@click="modals.createPlayer = true"
					>
						<i class="ni ni-diamond"></i>
						<span class="nav-link-text">Nouveau personnage</span>
					</li>

					<li class="nav-item">
						<a
							class="nav-link"
							href="https://discord.gg/sX4vz5jKmK"
							target="_blank"
						>
							<i class="ni ni-support-16"></i>
							<span class="nav-link-text">Contacter le support</span>
						</a>
					</li>
				</ul>
			</template>

			<template slot="links-after" v-if="isAdmin || isModo">
				<hr class="my-3">
				<h6 class="navbar-heading p-0 text-muted">Administration</h6>

				<ul class="navbar-nav mb-md-3">
					<li class="nav-item">
						<a class="nav-link" href="#/administration/dashboard">
							<i class="ni ni-tv-2"></i>
							<span class="nav-link-text">Tableau de bord</span>
						</a>
					</li>

					<li class="nav-item">
						<a class="nav-link" href="#/administration/users">
							<i class="ni ni-single-02"></i>
							<span class="nav-link-text">Utilisateurs</span>
						</a>
					</li>

					<li class="nav-item">
						<a class="nav-link" href="#/administration/creation">
							<i class="ni ni-ruler-pencil"></i>
							<span class="nav-link-text">Création</span>
						</a>
					</li>
					
					<sidebar-item :link="{name: 'Entreprises', icon: 'ni ni-building',}">
						<div v-for="company in allCompanies" :key="company.id">
							<sidebar-item :link="{ 'name': company.name, 'path': `/company/${company.id}` }"></sidebar-item>
						</div>
					</sidebar-item>
					<!--<li class="nav-item">
						<a class="nav-link" href="#/administration/economy">
							<i class="ni ni-shop"></i>
							<span class="nav-link-text">Économie</span>
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#/administration/stats">
							<i class="ni ni-chart-pie-35"></i>
							<span class="nav-link-text">Statistiques</span>
						</a>
					</li>-->
				</ul>
			</template>
		</side-bar>
		<div class="main-content">
			<dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

			<div @click="$sidebar.displaySidebar(false)">
				<fade-transition :duration="200" origin="center top" mode="out-in">
					<!-- your content here -->
					<router-view></router-view>
				</fade-transition>
			</div>
			<content-footer v-if="!$route.meta.hideFooter"></content-footer>
		</div>
		<modal
			:show.sync="modals.createPlayer"
			body-classes="p-0"
			modal-classes="modal-dialog-centered modal-md"
		>
			<div class="px-3">
				<validation-observer
					v-slot="{handleSubmit}"
					ref="formValidator"
				>
					<form
						role="form"
						@submit.prevent="handleSubmit(onSubmit)"
					>
						<h5 class="lead text-center">Création d'un nouveau personnage RP</h5>
						<base-input
							alternative
							class="mb-3"
							placeholder="Pseudo"
							name="Pseudo"
							:rules="{required: true}"
							v-model="model.player.pseudo"
						></base-input>

						<div class="row">
							<div class="col-6">
								<base-input
									alternative
									class="mb-3"
									placeholder="Nom"
									name="Nom"
									:rules="{required: true}"
									v-model="model.player.lastName"
								></base-input>
							</div>
							<div class="col-6">
								<base-input
									alternative
									class="mb-3"
									placeholder="Prénom"
									name="Prénom"
									:rules="{required: true}"
									v-model="model.player.firstName"
								></base-input>
							</div>
						</div>

						<base-input
							alternative
							type="date"
							name="Date de naissance"
							label="Date de naissance"
							:rules="{required: true}"
							v-model="model.player.birthDate"
						/>

						<div class="row text-center">
							<base-radio
								name="M"
								class="col"
								v-model="model.player.gender"
							>Homme</base-radio>
							<base-radio
								name="F"
								class="col"
								v-model="model.player.gender"
							>Femme</base-radio>
							<base-radio
								name="A"
								class="col"
								v-model="model.player.gender"
							>Autre</base-radio>
						</div>
						<div class="text-center">
							<button
								type="submit"
								class="btn btn-primary my-4"
							>Créer un compte</button>
						</div>
					</form>
				</validation-observer>
			</div>
		</modal>
	</div>
</template>
<script>
	import DashboardNavbar from './DashboardNavbar.vue';
	import ContentFooter from './ContentFooter.vue';
	import {
		FadeTransition
	} from 'vue2-transitions';

	export default {
		components: {
			DashboardNavbar,
			ContentFooter,
			FadeTransition
		},
		data() {
			return {
				userInfo: this.$models.user,
				playerInfo: this.$models.player,

				companies: [],
				allCompanies: [],
				activeCompany: this.$models.company,

				isAdmin: false,
				isModo: false,
				isBeta: false,

				authorizations: {
					firefighter: false,
					police: false,
					gov: false
				},

				players: [],

				modals: {
					createPlayer: false
				},

				model: {
					firstName: '',
					email: '',
					password: '',

					player: {
						pseudo: '',
						firstName: '',
						lastName: '',
						birthDate: '',
						gender: ''
					},

					privpolicy: false,
					guide: false,
				}
			}
		},
		methods: {
			initCompanies() {
				this.$db.collection("companies").where("active", "==", true).onSnapshot((res) => {
					var tempCompanies = [];
					var tempAllCompanies = [];

					res.forEach((document) => {
						tempAllCompanies.push({
							name: document.data().name,
							id: document.id
						});

						if(document.data().owner == this.playerInfo.id){
							tempCompanies.push({
								name: document.data().name,
								id: document.id
							});
						}
						else {
							this.$db.collection("companies").doc(document.id).collection("employees").where("employee", "==", this.playerInfo.id).where("status", "==", 1).get().then((res) => {

								res.forEach(() => {
									tempCompanies.push({
										name: document.data().name,
										id: document.id
									});
								});

							});
						}
					});

					this.companies = tempCompanies;
					this.allCompanies = tempAllCompanies;
				});
			},

			onSubmit() {
				const player = {
					...this.model.player,

					'owner': this.$firebase.auth().currentUser.uid,

					'creationDate': new Date(),
					'birthDate': new Date(this.model.player.birthDate),

					'address': {
						'street': '',
						'city': ''
					},

					'bio': '',

					'status': {
						'law': false,
						'suspended': {
							'is': true,
							'date': new Date(),
							'reason': '[STAFF] Attente d\'approbation du personnage.'
						},
						'dead': {
							'is': false,
							'date': ''
						}
					}
				};

				this.$db.collection('players').add(player).then((docRef) => {
					this.$db.collection('users').doc(this.$firebase.auth().currentUser.uid).set({
						'main': docRef.id
					}, {
						merge: true
					});

					this.$db.collection("notifications").add({
						processed: false,
						title: "Nouveau joueur",
						content: `Nouveau joueur à approuver.\n\nIdentité :\`\`\`${this.model.player.firstName} ${this.model.player.lastName.toUpperCase()}\`\`\`ID :\`\`\`${docRef.id}\`\`\``,

						emoji: "🎉",

						color: "2dce89",

						notiChannel: 435902232274272266,
						notiDate: new Date()
					});
				});

				this.$notify({type: 'success', message: `Votre compte a été créé !`});

				this.$router.replace({
					path: "/dashboard"
				});
			}
		},
		async created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();

				this.$db.collection("players").doc(this.userInfo.main).onSnapshot(doc => {
					this.playerInfo = {
						...doc.data(),
						id: doc.id
					};

					this.initCompanies();

					// Vérification pompiers
					this.$db.collection("public_services").doc("e3LYFzTIZlQpRE7XGv3p").collection("members").doc(this.playerInfo.id).onSnapshot((doc) => {
						this.authorizations.firefighter = doc.data() ? true : false;
					});

					// Vérification police
					this.$db.collection("public_services").doc("yDSIoH2qArdxv4Z00QBk").collection("members").doc(this.playerInfo.id).onSnapshot((doc) => {
						this.authorizations.police = doc.data() ? true : false;
					});

					// Vérification gouvernement
					this.$db.collection("public_services").doc("5tUnon3S4uWi40bPfON4").collection("members").doc(this.playerInfo.id).onSnapshot((doc) => {
						this.authorizations.gov = doc.data() ? true : false;
					});
				});
			});

			this.isBeta = (await this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).collection("ranks").doc("ETQljXETj1eclof86HCT").get()).data() ? true : false;
			this.isAdmin = (await this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).collection("ranks").doc("vuBvsUzdYoVfKBgEXPpS").get()).data() ? true : false;
			this.isModo = (await this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).collection("ranks").doc("CrzN6z6UytYPXB3uupcN").get()).data() ? true : false;


			// Get players
			this.$db.collection("players").where("status.dead.is", "==", false).where("status.suspended.is", "==", false).orderBy("lastName", "asc").onSnapshot((res) => {
				this.players = [];

				res.forEach((document) => {
					var data = document.data()

					this.players.push({
						label: data.lastName.toUpperCase() + " " + data.firstName,
						value: document.id
					});
				});
			});
		},
		watch: {
			$route: function() {
				if(this.$route.params.id){
					if(this.$route.path.includes("company")){
						this.$db.collection("companies").doc(this.$route.params.id).get().then((res) => {
							this.activeCompany = {
								...res.data(),
								id: res.id
							}
						});
					}
				}
				else {
					this.activeCompany = '';
				}
			}
		}
	};

</script>
<style lang="scss">
	.nav-link {
		cursor: pointer;
	}
</style>
