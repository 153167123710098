export let listUtils = {
	coolPhrases: [
		"Tourne toi vers le soleil et l'ombre sera derrière toi.",
		"Il n’y a pas de clés pour le bonheur, la porte est toujours ouverte",
		"Fais toujours de ton mieux, même si personne ne regarde",
		"Il y a des fleurs partout pour qui veut bien les voir.",
		"La vie, c’est comme une bicyclette, il faut avancer pour ne pas perdre l’équilibre.",
		"N'oublie pas de briller !",
		"Salut, c'est Stan 🥔.",
		"Sourire mobilise 15 muscles, mais faire la gueule en sollicite 40. Reposez-vous : souriez !",
		"Là où il y a une volonté, il y a un chemin.",
		"Il n'y a pas de honte à préférer le bonheur.",
		"Un seul moment de joie chasse cent moments de tristesse.",
		"Les gagnants trouvent des moyens, les perdants des excuses.",
		"N'attendez pas d'être heureux pour sourire, souriez plutôt afin d'être heureux.",
		"Fais n'importe quoi, mais tires-en de la joie.",
		"Un voyage de mille lieues commence toujours par un premier pas.",
		"L’échec est seulement l’opportunité de recommencer d’une façon plus intelligente.",
		"Il faut viser la lune, parce qu’au moins si vous échouez, vous finissez dans les étoiles.",
		"Les gagnants trouvent des moyens, les perdants des excuses."
	]
};
