/*!

=========================================================
* Vue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';

import firebase from "firebase/app";
import db from "./modules/firebase/firebaseInit";
import "firebase/auth";
import "firebase/performance";

//import './modules/userManager.js';

Vue.prototype.$db = db;

import moment_ from "moment";
Vue.prototype.$moment = moment_;
moment_.locale('fr');

import axios from "axios";
Vue.prototype.$axios = axios;

import { listUtils } from "./components/toLoad/listUtils.js";
import { fieldsRules } from "./components/toLoad/fieldsRules";
import { models } from "./components/toLoad/models";
import { functions } from "./components/toLoad/functions";
Vue.prototype.$listUtils = listUtils;
Vue.prototype.$fieldsRules = fieldsRules;
Vue.prototype.$models = models;
Vue.prototype.$functions = functions;

import VueRellax from 'vue-rellax';
Vue.use(VueRellax);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// header title
import VueHead from "vue-head";
Vue.use(VueHead, {
	separator: "—",
	complement: "HoriZon"
});

// router setup
import router from './routes/router';

import './registerServiceWorker';
// plugin setup
Vue.use(DashboardPlugin);

// Components global import
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);

import BaseSlider from '@/components/BaseSlider';
Vue.component(BaseSlider.name, BaseSlider);

/* eslint-disable no-new */
firebase.auth().onAuthStateChanged(() => {
	new Vue({
			el: '#app',
			render: h => h(App),
			router
		},
	);
});
