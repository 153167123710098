<template>
	<div class="content">
		<FadeTransition :duration="100" mode="out-in">
			<router-view/>
		</FadeTransition>
	</div>
</template>
<script>
	import {
		FadeTransition
	} from 'vue2-transitions';
	import jsonPackage from "@/../package.json";

	export default {
		components: {
			FadeTransition
		},
		data() {
			return {
				playerInfo: this.$models.player,
				userInfo: this.$models.user
			};
		},
		methods: {
			userAccountWarn(){
				const defaultSafeZone = [
					"/register",
					"/login",
					"/lock",
					"/options",
					"/administration/dashboard",
					"/administration/users"
				];

				const deathSafeZone = [...defaultSafeZone, "/character", "/profile"];
				const suspendedPlayerSafeZone = [...defaultSafeZone, "/character"];

				const suspendedAccountSafeZone = [...defaultSafeZone];

				function safeZone(arr, val) {
					return arr.some(function(arrVal) {
						return val === arrVal;
					});
				}

				if(this.playerInfo.status.dead.is && !safeZone(deathSafeZone, this.$route.path)){
					this.$notify({
						type: 'warning',
						message: `Votre personnage est décédé !`
					});

					this.$router.replace({
						path: "/character"
					});
				}

				if(this.playerInfo.status.suspended.is && !safeZone(suspendedPlayerSafeZone, this.$route.path)){
					this.$notify({
						type: 'warning',
						message: `Votre personnage est désactivé !`
					});

					this.$router.replace({
						path: "/character"
					});
				}

				if(this.userInfo.suspended.is && !safeZone(suspendedAccountSafeZone, this.$route.path)){
					this.$notify({
						type: 'warning',
						message: `Votre compte est désactivé !`
					});

					this.$router.replace({
						path: "/options"
					});
				}

				this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).collection("ranks").doc("YTrERYCixEaXWeZLXdoJ").get().then((doc) => {
					if(!doc.data() && !safeZone(suspendedAccountSafeZone, this.$route.path) && window.location.href.includes("beta")) {
						this.$notify({
							type: 'warning',
							message: "Vous n'êtes pas beta testeur !"
						});

						this.$router.replace({
							path: "/options"
						});
					}
				})
			},
		},
		watch: {
			playerInfo: function(){
				this.userAccountWarn();
			},
			userInfo: function(){
				this.userAccountWarn();
			},
			$route: function(){
				this.userAccountWarn();
			}
		},
		async created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(async (doc) => {
				this.userInfo = doc.data();

				this.$db.collection("players").doc(this.userInfo.main).onSnapshot(doc => {
					this.playerInfo = doc.data();
				});
			});
		}
	};
</script>
<style>
	.ql-container.ql-snow {
		border: none !important;
	}

	.ql-toolbar.ql-snow {
		border: none;
	}

	.ql-toolbar {
		border: 1px solid #dee2e6!important;
	}

	.el-select .el-input .el-input__inner {
		height: calc(1.5em + 1.25rem + 5px);
	}
	.el-select {
		width: 100%;
	}

	/* width */
	::-webkit-scrollbar {
		width: 3px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
		border-radius: 10px;
	}

	p {
		font-size: 0.9rem;
	}

	.fdanger-disabled, .fdanger-disabled:hover, .fdanger-disabled:active {
		color: #fff;
		background-color: #F87C95!important;
		border-color: #F87C95!important;

		cursor: default!important;
	}

	.fwarning-disabled, .fwarning-disabled:hover, .fwarning-disabled:active {
		color: #fff;
		background-color: #FC9983!important;
		border-color: #FC9983!important;

		cursor: default!important;
	}

	.fprimary-disabled, .fprimary-disabled:hover, .fprimary-disabled:active {
		color: #fff;
		background-color: #77B2FF!important;
		border-color: #77B2FF!important;

		cursor: default!important;
	}
</style>
