<template>
  <card class="card-stats" v-bind:class="{ 'card-glass': glassmorphism }" :show-footer-line="true">
    <div class="row">

      <div class="col">
        <slot>
          <h5 class="text-uppercase mb-0" v-bind:class="{ 'card-title-glass': glassmorphism, 'card-title text-muted': !glassmorphism }" v-if="title">{{title}}</h5>
          <span class="card-subtitle h2 font-weight-bold mb-0" v-bind:class="{ 'card-subtitle-glass': glassmorphism }" v-if="subTitle">{{subTitle}}</span>
        </slot>
      </div>

      <div class="col-auto" v-if="$slots.icon || icon">
        <slot name="icon">
          <div class="icon icon-shape text-white rounded-circle white-shadow"
               :class="[`bg-${type}`, iconClasses]">
            <i :class="icon"></i>
          </div>
        </slot>
      </div>
    </div>

    <p class="mt-3 mb-0 text-sm">
      <slot name="footer">

      </slot>
    </p>
  </card>
</template>
<script>
  import Card from './Card.vue';

  export default {
    name: 'stats-card',
    components: {
      Card
    },
    props: {
      type: {
        type: String,
        default: 'primary'
      },
      icon: String,
      title: String,
      subTitle: String,
      iconClasses: [String, Array],
      glassmorphism: {
        type: Boolean,
        default: true
      }
    }
  };
</script>
<style>
  .card-glass {
    background: rgba( 255, 255, 255, 0.2 )!important;
    box-shadow: 0 8px 32px 0 rgba( 255, 255, 255, 0.1 )!important;
    backdrop-filter: blur( 5px )!important;
    -webkit-backdrop-filter: blur( 5px )!important;
    border-radius: 10px!important;
  }

  .card-glass>h1,
  .card-glass>h2,
  .card-glass>h3,
  .card-glass>h4,
  .card-glass>h5,
  .card-glass>h6,
  .card-glass>small,
  .card-glass>label,
  .card-title-glass {
    color: white!important;
  }

  .card-glass>hr {
    border-top-color: white;
  }

  .card-subtitle-glass {
    color: white!important;
  }

  .white-shadow {
    box-shadow: 0 0 16px 0 rgba( 255, 255, 255, 0.2 )!important;
  }
</style>
