import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/performance";
import "firebase/remote-config";

import Vue from 'vue';

const firebaseConfig = {
	apiKey: "AIzaSyC2JEQ3eQQyunNSF0l0AXn54vPAmgnCkdY",
	authDomain: "wrp-horizon.firebaseapp.com",
	databaseURL: "https://wrp-horizon.firebaseio.com",
	projectId: "wrp-horizon",
	storageBucket: "wrp-horizon.appspot.com",
	messagingSenderId: "69635988736",
	appId: "1:69635988736:web:e968c33abcef20fb45a7e5",
	measurementId: "G-7VM485NM3W"
}

const firebaseApp = firebase.initializeApp(firebaseConfig);
Vue.prototype.$firebase = firebase;

firebase.performance();

import {
	initRemoteConfig
} from "./remoteConfig";
initRemoteConfig(Vue, firebase);

export default firebaseApp.firestore();