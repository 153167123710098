<template>
	<div class="main">
		<div class="main-content">
			<notifications></notifications>

			<zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
				<router-view></router-view>
			</zoom-center-transition>
		</div>
	</div>
</template>
<script>
	import {
		ZoomCenterTransition
	} from 'vue2-transitions';

	export default {
		components: {
			ZoomCenterTransition
		},
		props: {
			backgroundColor: {
				type: String,
				default: 'black'
			}
		},
		data() {
			return {
				showMenu: false,
				menuTransitionDuration: 250,
				pageTransitionDuration: 200,
				pageClass: 'login-page'
			};
		},
		computed: {
			title() {
				return `${this.$route.name} Page`;
			}
		},
		methods: {
			toggleNavbar() {
				document.body.classList.toggle('nav-open');
				this.showMenu = !this.showMenu;
			},
			closeMenu() {
				document.body.classList.remove('nav-open');
				this.showMenu = false;
			},
			setBackgroundColor() {
				document.body.classList.add('wallBg');
			},
			removeBackgroundColor() {
				document.body.classList.remove('wallBg');
			},
			updateBackground() {
				if (!this.$route.meta.noBodyBackground) {
					this.setBackgroundColor();
				} else {
					this.removeBackgroundColor()
				}
			}
		},
		beforeDestroy() {
			this.removeBackgroundColor();
		},
		beforeRouteUpdate(to, from, next) {
			// Close the mobile menu first then transition to next page
			if (this.showMenu) {
				this.closeMenu();
				setTimeout(() => {
					next();
				}, this.menuTransitionDuration);
			} else {
				next();
			}
		},
		watch: {
			$route: {
				immediate: true,
				handler: function () {
					this.updateBackground()
				}
			}
		}
	};

</script>
<style lang="scss">
	.wallBg {
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		background-position: top;
		background-image: url("https://wetstonrp.com/assets/images/neil-rosenstech-_FpwbchiVls-unsplash.jpg");
	}

	$scaleSize: 0.8;

	@keyframes zoomIn8 {
		from {
			opacity: 0;
			transform: scale3d($scaleSize, $scaleSize, $scaleSize);
		}

		100% {
			opacity: 1;
		}
	}

	.main-content .zoomIn {
		animation-name: zoomIn8;
	}

	@keyframes zoomOut8 {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
			transform: scale3d($scaleSize, $scaleSize, $scaleSize);
		}
	}

	.main-content .zoomOut {
		animation-name: zoomOut8;
	}

</style>
