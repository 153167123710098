<template>
	<base-nav container-classes="container-fluid" class="navbar-top border-bottom navbar-expand" :class="{'bg-wetstonColorLight navbar-dark': type === 'default'}">
		<!-- Search form -->
		<!--<form class="navbar-search form-inline mr-sm-3" :class="{'navbar-search-light': type === 'default', 'navbar-search-dark': type === 'light'}" id="navbar-search-main">
			<div class="form-group mb-0">
				<div class="input-group input-group-alternative input-group-merge">
					<div class="input-group-prepend">
						<span class="input-group-text"><i class="fas fa-search"></i></span>
					</div>
					<input class="form-control" placeholder="Search" type="text">
				</div>
			</div>
			<button type="button" class="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
				<span aria-hidden="true">×</span>
			</button>
		</form>-->
		<!-- Navbar links -->

		<div class="mr-3 pb-1">
			<badge
				rounded
				type="primary pb-1"
				v-if="currentURL.includes('localhost:')"
			><i class="fa-solid fa-terminal mr-1"></i> localhost</badge>
			<badge
				rounded
				type="primary pb-1"
				v-else-if="currentURL.split('.').length >= 4"
			><i class="fas fa-code-branch mr-1"></i> {{ currentURL.split(".")[0].replace(/(http:\/\/)|(https:\/\/)/, "") }}</badge>
		</div>

		<div v-if="this.playerInfo.status.suspended.is || this.playerInfo.status.dead.is || this.userInfo.suspended.is">
			<div class="row">
				<div class="col-auto mt-2">
					<h4 class="text-white" v-if="this.playerInfo.status.suspended.is">
						⚠️ Votre personnage ({{ playerInfo.firstName }} {{ playerInfo.lastName[0].toUpperCase() }}.) est désactivé. ⏸️
					</h4>
					<h4 class="text-white" v-else-if="this.playerInfo.status.dead.is">
						⚠️ Votre personnage ({{ playerInfo.firstName }} {{ playerInfo.lastName[0].toUpperCase() }}.) est mort. 💀
					</h4>
					<h4 class="text-white" v-else-if="this.userInfo.suspended.is">
						⚠️ Votre compte est désactivé.
					</h4>
				</div>
				<div class="col mt-1">
					<base-button v-if="this.playerInfo.status.suspended.is || this.playerInfo.status.dead.is" size="sm" type="primary" @click="$router.replace({path: '/character'});">En savoir plus</base-button>
					<base-button v-else size="sm" type="primary" @click="$router.replace({path: '/options'});">En savoir plus</base-button>
				</div>

			</div>
		</div>

		<ul class="navbar-nav align-items-center ml-md-auto">
			<li class="nav-item d-xl-none">
				<!-- Sidenav toggler -->
				<div class="pr-3 sidenav-toggler" :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}" @click="toggleSidebar">
					<div class="sidenav-toggler-inner">
						<i class="sidenav-toggler-line"></i>
						<i class="sidenav-toggler-line"></i>
						<i class="sidenav-toggler-line"></i>
					</div>
				</div>
			</li>
		<!--<base-dropdown class="nav-item" tag="li" title-classes="nav-link" title-tag="a" icon="ni ni-bell-55" menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden">
				<template>
					<div class="px-3 py-3">
						<h6 class="text-sm text-muted m-0">You have <strong class="text-primary">13</strong> notifications.</h6>
					</div>

					<div class="list-group list-group-flush">
						<a href="#!" class="list-group-item list-group-item-action">
							<div class="row align-items-center">
								<div class="col-auto">
									<img alt="Image placeholder" src="img/theme/team-1.jpg" class="avatar rounded-circle">
								</div>
								<div class="col ml--2">
									<div class="d-flex justify-content-between align-items-center">
										<div>
											<h4 class="mb-0 text-sm">John Snow</h4>
										</div>
										<div class="text-right text-muted">
											<small>2 hrs ago</small>
										</div>
									</div>
									<p class="text-sm mb-0">Let's meet at Starbucks at 11:30. Wdyt?</p>
								</div>
							</div>
						</a>
					</div>
					<a href="#!" class="dropdown-item text-center text-primary font-weight-bold py-3">View all</a>
				</template>
			</base-dropdown>-->
			<base-dropdown menu-classes="dropdown-menu dropdown-menu-dark dropdown-menu-right card-glass" class="nav-item" tag="li" title-tag="a" title-classes="nav-link" icon="ni ni-mobile-button"
			v-if="!this.playerInfo.status.suspended.is && !this.playerInfo.status.dead.is && !this.userInfo.suspended.is">
				<template>
					<div class="row shortcuts px-4">
						<span class="col-12 shortcut-item text-white" @click="modals.callEmergency = true">
							<span class="shortcut-media avatar rounded-circle bg-gradient-warning">
								<i class="fa fa-first-aid" aria-hidden="true"></i>
							</span>
							<small>Appel d'urgence</small>
						</span>

						<span class="col-12 shortcut-item text-white" @click="modals.searchCivilian = true" v-if="authorizations.firefighter || authorizations.police">
							<span class="shortcut-media avatar rounded-circle bg-gradient-success">
								<i class="fa fa-id-card" aria-hidden="true"></i>
							</span>
							<small>Vérif' Citoyen</small>
						</span>
					</div>
				</template>
			</base-dropdown>

			<base-dropdown
				menu-classes="dropdown-menu-lg dropdown-menu-dark card-glass dropdown-menu-right"
				class="nav-item"
				tag="li"
				title-tag="a"
				title-classes="nav-link"
				icon="fa-regular fa-hard-drive"
				v-if="(authorizations.police || authorizations.firefighter) && (!this.playerInfo.status.suspended.is && !this.playerInfo.status.dead.is && !this.userInfo.suspended.is)"
			>
				<template>
					<div class="row shortcuts px-4">
						<a href="#/secuvite" class="col-6 shortcut-item" v-if="authorizations.secuvite">
							<span class="shortcut-media avatar rounded-circle bg-gradient-success">
								<i class="fa fa-heartbeat"></i>
							</span>
							<small>Sécuvité</small>
						</a>
						<a
							href="#/cad/yDSIoH2qArdxv4Z00QBk"
							class="col-6 shortcut-item"
							v-if="authorizations.police"
						>
							<span class="shortcut-media avatar rounded-circle bg-gradient-wetstonColorLight">
								<i class="fa fa-shield-alt"></i>
							</span>
							<small>Police</small>
						</a>
						<a
							href="#/cad/e3LYFzTIZlQpRE7XGv3p"
							class="col-6 shortcut-item"
							v-if="authorizations.firefighter"
						>
							<span class="shortcut-media avatar rounded-circle bg-gradient-red">
								<i class="fa fa-fire"></i>
							</span>
							<small>Pompier</small>
						</a>
						<a href="#!" v-if="authorizations.judge" class="col-6 shortcut-item">
							<span class="shortcut-media avatar rounded-circle bg-gradient-primary">
								<i class="fa fa-gavel"></i>
							</span>
							<small>Juge</small>
						</a>
						<a
							href="#/cad/5tUnon3S4uWi40bPfON4"
							v-if="authorizations.government"
							class="col-6 shortcut-item"
						>
							<span class="shortcut-media avatar rounded-circle bg-gradient-black">
								<i class="fa fa-university"></i>
							</span>
							<small>Gouv'</small>
						</a>
					</div>
				</template>
			</base-dropdown>
		</ul>
		<ul class="navbar-nav align-items-center ml-auto ml-md-0">
			<base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
				<a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
					<div class="media align-items-center">
						<span class="avatar avatar-sm rounded-circle">
							<img :src="'https://crafatar.com/avatars/' + playerInfo.uuid">
						</span>
						<div class="media-body ml-2 d-none d-lg-block">
							<span class="mb-0 text-sm  font-weight-bold">{{ playerInfo.firstName }} {{ playerInfo.lastName[0].toUpperCase() }}.</span>
						</div>
					</div>
				</a>

				<template>

					<div class="dropdown-header noti-title">
						<h6 class="text-overflow m-0">Vous jouez en tant que <br/>{{ playerInfo.firstName }} {{ playerInfo.lastName }}</h6>
					</div>
					<a href="/#/character" class="dropdown-item">
						<i class="ni ni-single-02"></i>
						<span>Mon profil</span>
					</a>
					<a href="/#/options" class="dropdown-item">
						<i class="ni ni-settings-gear-65"></i>
						<span>Paramètres</span>
					</a>
					<!--<a href="#!" class="dropdown-item">
						<i class="ni ni-calendar-grid-58"></i>
						<span>Activité</span>
					</a>-->
					<a href="https://wetstonrp.atlassian.net/servicedesk/customer/portals" target="_blank" class="dropdown-item">
						<i class="ni ni-support-16"></i>
						<span>Support</span>
					</a>
					<div class="dropdown-divider"></div>
					<a href="/" class="dropdown-item" @click="signOut">
						<i class="ni ni-user-run"></i>
						<span>Se déconnecter</span>
					</a>

				</template>
			</base-dropdown>
		</ul>

		<modal
			:show.sync="modals.callEmergency"
			body-classes="p-0"
			modal-classes="modal-dialog-centered modal-sm"
			glass
			hugefade
		>
			<div class="px-3 my-3">
				<div class="row">
					<h1 class="col">Appel d'urgence</h1>
					<i class="fa-solid fa-tower-broadcast col-auto fa-xl pt-3 text-white"></i>
				</div>

				<hr class="mb-4 mt-2">

				<h4>Service concerné</h4>
				<div class="row ml-4">
					<base-radio
						name="police"
						class="mb-3 col"
						v-model="callDetails.service"
					>
						Police
					</base-radio>

					<base-radio
						name="pompiers"
						class="mb-3 col"
						v-model="callDetails.service"
					>
						Pompiers
					</base-radio>
				</div>

				<base-input
					alternative
					label="Lieu"
					v-model="callDetails.location"
				></base-input>
				<h5 class="mt--3 mb-3">Soyez précis, indiquez le nom de la ville si possible !</h5>

				<base-input
					alternative
					label="Détails"
				>
					<textarea
						class="form-control"
						rows="2"
						v-model="callDetails.emergency"
					></textarea>
				</base-input>
				<h5 class="mt--3 mb-3">Décrivez clairement la situation.</h5>
			</div>
			<div class="text-center mb-3">
				<base-button
					type="danger"
					@click="sendCall"
				>
					<i class="fa-solid fa-phone mr-3"></i>Envoyer
				</base-button>
			</div>
		</modal>

		<modal :show.sync="modals.searchCivilian" body-classes="p-0" modal-classes="modal-dialog-centered modal-lg">
			<template v-slot:header>
				<h5 class="modal-title">
					Base de données HoriZon<small class="text-muted">, {{ players.length }} citoyens recensés</small>
				</h5>
			</template>
			<card type="secondaryLegacy" class="border-0">
				<div class="row text-center">
					<base-input alternative class="col" placeholder="Entrez le nom, prénom ou @tag du civil" v-model="search.main" @keyup.enter="searchCivilians()"></base-input>
					<div class="col-auto">
						<base-button type="primary" @click="searchCivilians()">Chercher</base-button>
					</div>
				</div>

				<hr class="mt-2">

				<div style="max-height: 50vh; overflow-y: scroll; overflow-x: hidden;">
					<p v-if="search.main[0]">Affichage de {{ activePlayers.length }} résultats sur {{ players.length }} civils.</p>

					<div class="row" v-for="player in activePlayers" :key="player.id">
						<card class="col mb-2">
							<div class="row align-items-center">
								<div class="col-auto px-5">
									<div class="card-profile-image ml--2">
										<img :src="'https://crafatar.com/avatars/' + player.uuid" class="rounded-circle" width="70px">
									</div>
								</div>
								<div class="col pl-0">
									<h3>
										<i
											class="fa-regular fa-circle-pause"
											v-show="player.status.suspended.is"
										></i>
										{{player.firstName}} {{player.lastName.toUpperCase()}}
										<small class="text-muted">{{ player.gender = "M" ? "Homme" : "Femme" }}, {{ Math.round($moment().diff($moment(player.birthDate.toDate()), 'years', true)) }} ans</small>
									</h3>
									<div class="row">
										<small class="col-12 col-md">@{{player.pseudo}}</small>
										<small
											class="col-12 col-md"
											v-if="player.address.street"
										>{{player.address.street}}, {{player.address.city}}</small>
									</div>
								</div>

								<div class="col-12 col-md-3 mt-3 mt-md-auto">
									<div class="row">
										<base-button
											size="sm"
											type="primary"
											class="col-12 mb-2"
											outline
											disabled
											v-if="false"
										>Ajouter une fiche</base-button>
										<base-button
											size="sm"
											type="default"
											class="col-12 mb-2"
											outline
											@click="loadCivilian(player)"
										>Accès rapide</base-button>
										<base-button
											size="sm"
											type="info"
											class="col-12"
											outline
											@click="$router.push('/citizen/' + player.id), modals.searchCivilian = false"
										>Fiche Citoyen<i class="fa-solid fa-arrow-up-right-from-square mx-1"></i></base-button>
									</div>
								</div>
							</div>
						</card>
					</div>					
				</div>
			</card>
		</modal>

		<modal :show.sync="modals.showCivilian" body-classes="p-0" modal-classes="modal-dialog-centered modal-xl">
			<template v-slot:header>
				<h5
					class="modal-title"
					id="civilianModal"
				>
					<i
						class="fa-regular fa-circle-pause"
						v-show="activePlayer.status.suspended.is"
					></i>
					{{activePlayer.firstName}} {{activePlayer.lastName.toUpperCase()}}<small class="text-muted">, {{ Math.round($moment().diff($moment(activePlayer.birthDate.toDate()), 'years', true)) }} ans</small>
				</h5>
			</template>
			<card type="secondaryLegacy" shadow header-classes="bg-white" class="border-0">
				<div class="row align-items-center">
					<div class="col-auto">
						<img :src="'https://minotar.net/body/' + activePlayer.pseudo + '/100.png'">
					</div>
					<div class="col">
						<div class="row">
							<div class="col-12 row">
								<div class="col-6">
									<h5>Gestion civil</h5>
									<div class="row ml-0">
										<base-button size="sm" type="success" outline class="mb-1" @click="loadCivilian(activePlayer)">Actualiser</base-button>
										<base-button size="sm" type="primary" outline class="mb-1" disabled>Ajouter une fiche</base-button>
									</div>
								</div>


								<div class="col-6" v-if="isAdmin || isModo">
									<h5>Administration</h5>
									<div class="row ml-0">
										<base-button size="sm" type="danger" outline class="mb-1">Désactiver</base-button>
									</div>
								</div>
							</div>

							<div class="col-12">
								<hr class="my-4" />

								<card>
									<div class="row">
										<small class="col-12 col-lg-5">Tag : @{{ activePlayer.pseudo }}</small>
										<small class="col-12 col-lg-7">Genre : {{ activePlayer.gender = "M" ? "Homme" : "Femme" }}</small>
										<small class="col-12 col-lg-5">Date de naissance : {{ $moment(activePlayer.birthDate.toDate()).format('DD/MM/YYYY') }}</small>
										<small class="col-12 col-lg-7" v-if="activePlayer.address.street">Adresse : {{ activePlayer.address.street }}, {{ activePlayer.address.city }}</small>

										<template v-if="isAdmin || isModo">
											<br/>

											<small class="col-12 col-lg-5">ID : {{ activePlayer.id }}</small>
											<small class="col-12 col-lg-7">JoinDate : {{ $moment(activePlayer.creationDate.toDate()).format('DD/MM/YYYY à HH:mm') }}</small>
											<small class="col-12">Owner ID : {{ activePlayer.owner }}</small>
										</template>
									</div>
								</card>
							</div>
						</div>
					</div>
				</div>

				<hr class="mb-4 mt-0"/>

				<div class="row">
					<div class="col-12 col-xl">
						<h3>Dernières fiches</h3>

						<template v-if="authorizations.firefighter">
							<div class="row">
								<div class="col-12 col-lg-4">
									<card :gradient="serviceInfo.firefighter.style.color">

										<div class="row text-center">
											<div class="col-12">
												<i :class="`fa fa-${serviceInfo.firefighter.style.icon} text-white fa-xl`"></i>
												<h3 class="text-white mt-3 mb-0">État de Wetston</h3>
												<h3 class="text-white my-0">—</h3>
												<h3 class="text-white my-0">{{ serviceInfo.firefighter.name }}</h3>

												<hr
													style="border-top-color: white;"
													class="mt-4"
												>
											</div>

											<div class="col-12">
												<div class="row">
													<div class="col text-left">
														<span class="h5 surtitle text-bold text-white">
															<i class="far fa-edit"></i> Fiches
														</span>
													</div>
													<div class="col-auto">
														<h4 class="text-white">{{ userReports.firefighter.length }}</h4>
													</div>
												</div>
											</div>

											<div class="col-12">
												<div class="row">
													<div class="col text-left">
														<span class="h5 surtitle text-bold text-white">
															<i class="far fa-hospital"></i> Visites
														</span>
													</div>
													<div class="col-auto">
														<h4 class="text-white">{{ userReports.firefighter.filter(x => x.type.toLowerCase() === 'visite_medicale' ).length }}</h4>
													</div>
												</div>
											</div>

											<div class="col-12">
												<div class="row">
													<div class="col text-left">
														<span class="h5 surtitle text-bold text-white">
															<i class="far fa-clipboard"></i> Rapports
														</span>
													</div>
													<div class="col-auto">
														<h4 class="text-white">{{ userReports.firefighter.filter(x => x.type.toLowerCase() === 'rapport' ).length }}</h4>
													</div>
												</div>
											</div>

											<div class="col-12">
												<div class="row">
													<div class="col text-left">
														<span class="h5 surtitle text-bold text-white">
															<i class="fa fa-tower-broadcast"></i> Inter'
														</span>
													</div>
													<div class="col-auto">
														<h4 class="text-white">{{ userReports.firefighter.filter(x => x.type.toLowerCase() === 'intervention' ).length }}</h4>
													</div>
												</div>
											</div>
										</div>

									</card>
								</div>
								<div class="col">
									<div
										class="row"
										v-if="userReports.firefighter.length > 0"
									>
										<div
											v-for="report in userReports.firefighter.slice(0, 4)"
											:key="report.id"
											class="col-lg-6 col-sm-12 mb-2"
										>
											<PublicReport
												:report="report"
												type="Pompier"
												:displayNicer="true"
											/>
										</div>
									</div>
									<p v-else>Ce civil n'a aucune fiche.</p>
								</div>
							</div>
						</template>

						<template v-if="authorizations.police">
							<div class="row">
								<div class="col-12 col-lg-4">
									<card :gradient="serviceInfo.police.style.color">

										<div class="row text-center">
											<div class="col-12">
												<i :class="`fa fa-${serviceInfo.police.style.icon} text-white fa-xl`"></i>
												<h3 class="text-white mt-3 mb-0">État de Wetston</h3>
												<h3 class="text-white my-0">—</h3>
												<h3 class="text-white my-0">{{ serviceInfo.police.name }}</h3>

												<hr
													style="border-top-color: white;"
													class="mt-4"
												>
											</div>

											<div class="col-12">
												<div class="row">
													<div class="col text-left">
														<span class="h5 surtitle text-bold text-white">
															<i class="far fa-edit"></i> Fiches
														</span>
													</div>
													<div class="col-auto">
														<h4 class="text-white">{{ userReports.police.length }}</h4>
													</div>
												</div>
											</div>

											<div class="col-12">
												<div class="row">
													<div class="col text-left">
														<span class="h5 surtitle text-bold text-white">
															<i class="fa fa-handcuffs"></i> Admissions
														</span>
													</div>
													<div class="col-auto">
														<h4 class="text-white">{{ userReports.police.filter(x => x.type.toLowerCase() === 'admission' ).length }}</h4>
													</div>
												</div>
											</div>

											<div class="col-12">
												<div class="row">
													<div class="col text-left">
														<span class="h5 surtitle text-bold text-white">
															<i class="far fa-clipboard"></i> Rapports
														</span>
													</div>
													<div class="col-auto">
														<h4 class="text-white">{{ userReports.police.filter(x => x.type.toLowerCase() === 'rapport' ).length }}</h4>
													</div>
												</div>
											</div>

											<div class="col-12">
												<div class="row">
													<div class="col text-left">
														<span class="h5 surtitle text-bold text-white">
															<i class="fa fa-tower-broadcast"></i> Inter'
														</span>
													</div>
													<div class="col-auto">
														<h4 class="text-white">{{ userReports.police.filter(x => x.type.toLowerCase() === 'intervention' ).length }}</h4>
													</div>
												</div>
											</div>
										</div>

									</card>
								</div>
								<div class="col">
									<div
										class="row"
										v-if="userReports.police.length > 0"
									>
										<div
											v-for="report in userReports.police.slice(0, 4)"
											:key="report.id"
											class="col-lg-6 col-sm-12 mb-2"
										>
											<PublicReport
												:report="report"
												type="Police"
												:playerInfo="activePlayer"
												:displayNicer="true"
											/>
										</div>
									</div>
									<p v-else>Ce civil n'a aucune fiche.</p>
								</div>
							</div>
						</template>
					</div>
					<div class="col-12 col-xl-3" v-show="authorizations.police">
						<h3>Permis</h3>

						<div class="row">
							<div class="col col-xl-12">
								<h4 class="mt-2"><i
										class="fa fa-dharmachakra"
										aria-hidden="true"
									></i> Véhicules</h4>
								<div v-if="userLicenses[1].length > 0">
									<span
										v-for="license in userLicenses[1]"
										:key="license.id"
									>
										<p
											class="mb-0"
											v-if="license.type == 'ground'"
										>
											Auto / Moto
											<small>- Points : {{license.points}}</small>

											<i
												class="far fa-check-circle ml-2 text-success"
												@click="modals.removePoints = true"
											></i>
										</p>
										<p
											class="mb-0"
											v-else
										>Hélicoptère / Avion</p>
									</span>
								</div>
								<div
									v-else
									class="mt-2"
								>
									<h5>Ce civil n'a pas de permis.</h5>
								</div>
							</div>
							<div class="col col-xl-12">
								<h4 class="mt-2"><i
										class="fa fa-clipboard"
										aria-hidden="true"
									></i> Armes</h4>
								<div v-if="userLicenses[2].length > 0">
									<span
										v-for="license in userLicenses[2]"
										:key="license.id"
									>
										<p class="mb-0">Permis {{ license.type.toUpperCase() }}</p>
									</span>
								</div>
								<div
									v-else
									class="mt-2"
								>
									<h5>Ce civil n'a pas de permis d'armement.</h5>
								</div>
							</div>
							<div class="col col-xl-12">
								<h4 class="mt-2"><i
										class="fa fa-wine-bottle"
										aria-hidden="true"
									></i> Licenses</h4>
								<div v-if="userLicenses[3].length > 0">
									<span
										v-for="license in userLicenses[3]"
										:key="license.id"
									>
										<p class="mb-0">Permis {{ license.type.toUpperCase() }}</p>
									</span>
								</div>
								<div
									v-else
									class="mt-2"
								>
									<h5>Ce civil n'a pas de licenses.</h5>
								</div>
							</div>
						</div>

					</div>
				</div>
			</card>
		</modal>

		<modal
			:show.sync="modals.removePoints"
			body-classes="p-0"
			modal-classes="modal-dialog-centered modal-sm"
			glass
			hugefade
		>
			<div class="px-3 my-3">
				<div class="row">
					<h1 class="col">Modification de permis</h1>
					<i class="far fa-id-card col-auto fa-xl pt-3 text-white"></i>
				</div>

				<hr class="mb-4 mt-2">

				<base-input alternative class="mb-3" name="Points" label="Points" type="number" input-classes="form-control-lg" v-model="activeCarLicense.points" max="12" min="0"></base-input>
			</div>
			<div class="text-center mb-3">
				<base-button
					type="primary"
					@click="setLicensePoints(activeCarLicense.id)"
				>Modifier</base-button>
			</div>
		</modal>
	</base-nav>
</template>
<script>
	import { BaseNav } from '@/components';
	import PublicReport from '@/views/Components/HRZ/PublicReport';

	export default {
		components: {
			BaseNav,
			PublicReport
		},
		props: {
			type: {
				type: String,
				default: 'default', // default|light
				description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
			}
		},
		computed: {
			routeName() {
				const {
					name
				} = this.$route;
				return this.capitalizeFirstLetter(name);
			}
		},
		data() {
			return {
				activeNotifications: false,
				showMenu: false,
				searchModalVisible: false,
				searchQuery: '',

				players: [],
				activePlayers: [],

				userInfo: this.$models.user,
				playerInfo: this.$models.player,

				activePlayer: {
					...this.$models.player
				},

				activeCarLicense: {
					points: '',
					id: ''
				},

				authorizations: {
					firefighter: false,
					police: false,
					government: false
				},

				modals: {
					callEmergency: false,
					searchCivilian: false,
					showCivilian: false,
					removePoints: false
				},

				callDetails: {
					...this.$models.emergencyDetails,
					service: ''
				},

				search: {
					main: ''
				},

				userReports: {
					firefighter: [],
					police: []
				},

				userLicenses: {
					'1': [],
					'2': [],
					'3': []
				},

				isAdmin: false,
				isModo: false,

				serviceInfo: {},

				currentURL: window.location.href
			};
		},
		methods: {
			capitalizeFirstLetter(string) {
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			toggleNotificationDropDown() {
				this.activeNotifications = !this.activeNotifications;
			},
			closeDropDown() {
				this.activeNotifications = false;
			},
			toggleSidebar() {
				this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
			},
			hideSidebar() {
				this.$sidebar.displaySidebar(false);
			},
			async signOut() {
				try {
					await this.$firebase.auth().signOut();
					this.$router.replace({
						path: "Login"
					});
				} catch (err) {
					console.log(err);
				}
			},

			sendCall() {
				if (!this.callDetails.service || !this.callDetails.emergency || !this.callDetails.location) return this.$notify({ type: 'danger', message: `L'appel n'est pas complet !` });

				var dispatchID = this.callDetails.service == "police" ? "yDSIoH2qArdxv4Z00QBk" : "e3LYFzTIZlQpRE7XGv3p";

				delete this.callDetails.service;

				this.$db.collection("public_services").doc(dispatchID).collection("calls").add({
					...this.callDetails,
					date: new Date(),
					status: 0,
					caller: this.playerInfo.id
				});

				this.$notify({ type: 'success', message: `Votre appel a été envoyé, les secours arrivent !` });
			},

			setLicensePoints(id) {
				this.$db.collection("players").doc(this.activePlayer.id).collection("licenses").doc(id).set({
					points: Number(this.activeCarLicense.points)
				}, {
					merge: true
				}).then(() => {
					this.loadCivilian(this.activePlayer);

					this.$notify({type: 'success', message: `${this.activePlayer.firstName} ${this.activePlayer.lastName} possède maintenant ${this.activeCarLicense.points} points.`});
				})
			},

			searchCivilians() {
				if(!this.search.main[0]) return this.activePlayers = this.players;

				this.activePlayers = this.players.filter(player => {
					var regexExp = new RegExp(`${this.search.main.replace("@", "")}`, "img");

					var splitSearch = this.search.main.split(" ");

					if(splitSearch.length > 0){
						var regexExpFirstName = new RegExp(`${splitSearch[0]}`, "img");
						var regexExpLastName = new RegExp(`${splitSearch[1]}`, "img");

						if (player.firstName.match(regexExpFirstName)) {
							return true;
						}
						else if (player.lastName.match(regexExpLastName)) return true;
					}

					if (player.firstName.match(regexExp)) return true;
					else if (player.lastName.match(regexExp)) return true;
					else if (player.pseudo.match(regexExp)) return true;
				});
			},

			loadCivilian(player){
				this.activePlayer = player;

				this.userReports.police = this.userReports.firefighter = [];
				this.userLicenses = {
					'1': [],
					'2': [],
					'3': []
				};

				this.$db.collection("public_services").doc("e3LYFzTIZlQpRE7XGv3p").collection("reports").where("owner", "==", player.id).get().then(res => {
					this.userReports.firefighter = [];

					res.forEach((document) => {
						this.userReports.firefighter.push({
							...document.data(),
							id: document.id
						});
					});

					this.userReports.firefighter.sort((a, b) => {
						return b.index - a.index;
					});
				});

				const policeReportsRef = this.$db.collection("public_services").doc("yDSIoH2qArdxv4Z00QBk").collection("reports");

				policeReportsRef.where('suspect', '==', player.id).get().then((res) => {
					res.forEach((doc) => {
						this.userReports.police.push({
							...doc.data(),
							id: doc.id
						});
					});
				});
				policeReportsRef.where('victim', '==', player.id).get().then((res) => {
					res.forEach((doc) => {
						this.userReports.police.push({
							...doc.data(),
							id: doc.id
						});
					});

					this.userReports.police.sort((a, b) => {
						return b.index - a.index;
					});
				});

				this.$db.collection("players").doc(player.id).collection("licenses").get().then((queryLicenses) => {
					queryLicenses.forEach((license) => {
						this.userLicenses[String(license.data().category)].push({
							...license.data(),
							id: license.id
						});

						if (license.data().category == 1 && license.data().type == "ground") {
							this.activeCarLicense = {
								points: license.data().points,
								id: license.id
							}
						}
					});
				});

				this.modals.showCivilian = true
			}
		},
		async created() {
			var currUser = this.$firebase.auth().currentUser

			this.$db.collection("users").doc(currUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();

				this.$db.collection("players").doc(this.userInfo.main).onSnapshot(doc => {
					this.playerInfo = {
						...doc.data(),
						id: doc.id,
						uuid: doc.data().uuid || this.$models.player.uuid
					};

					// Vérification pompiers
					this.$db.collection("public_services").doc("e3LYFzTIZlQpRE7XGv3p").collection("members").doc(this.playerInfo.id).onSnapshot((doc) => {
						this.authorizations.firefighter = doc.data() ? true : false;
					});

					// Vérification police
					this.$db.collection("public_services").doc("yDSIoH2qArdxv4Z00QBk").collection("members").doc(this.playerInfo.id).onSnapshot((doc) => {
						this.authorizations.police = doc.data() ? true : false;
					});

					// Vérification gouvernement
					this.$db.collection("public_services").doc("5tUnon3S4uWi40bPfON4").collection("members").doc(this.playerInfo.id).onSnapshot((doc) => {
						this.authorizations.government = doc.data() ? true : false;
					});
				});
			});

			this.$db.collection("users").doc(currUser.uid).collection("ranks").doc("vuBvsUzdYoVfKBgEXPpS").get().then((doc) => this.isAdmin = doc.data() ? true : false);
			this.$db.collection("users").doc(currUser.uid).collection("ranks").doc("CrzN6z6UytYPXB3uupcN").get().then((doc) => this.isModo = doc.data() ? true : false);

			this.$db.collection("players").where("status.dead.is", "==", false).where("status.suspended.is", "==", false).get().then((querySnapshot) => {
				this.players = [];

				querySnapshot.forEach((doc) => {
					this.players.push({
						...doc.data(),
						id: doc.id
					});
				});

				this.activePlayers = this.players;
			});

			this.$db.collection("public_services").onSnapshot((services) => {
				services.forEach((service) => {
					this.serviceInfo[service.data().label] = {
						...service.data(),
						id: service.id
					}
				})
			});
		}
	};

</script>
