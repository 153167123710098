<template>
	<footer class="footer px-4 mx-2">
		<div class="row align-items-center justify-content-lg-between">
			<div class="col-lg-6">
				<div class="copyright text-center text-lg-left text-muted">
					© {{ new Date().getFullYear() }}
					~ Fait avec 💙 par <a
						href="https://www.stan1712.com"
						class="font-weight-bold"
						target="_blank"
					>Stan</a>
					~ Version
					<span v-if="currentURL.includes('localhost:')"
					>localhost</span>
					<span v-else-if="currentURL.split('.').length >= 4">
						{{ currentURL.split(".")[0].replace(/(http:\/\/)|(https:\/\/)/, "") }}
					</span>
					{{ version }}
				</div>
			</div>
			<div class="col-lg-6">
				<ul class="nav nav-footer justify-content-center justify-content-lg-end">
					<li class="nav-item">
						<a href="https://wetstonrp.com/" class="nav-link" target="_blank">Site</a>
					</li>
					<li class="nav-item">
						<a href="https://forum.wetstonrp.com/" class="nav-link" target="_blank">Forum</a>
					</li>
					<li class="nav-item">
						<a href="https://discord.gg/5jnjvzs" class="nav-link" target="_blank">Discord</a>
					</li>
				</ul>
			</div>
		</div>
	</footer>
</template>
<script>
	import jpackage from "@/../package.json";

	export default {
		data() {
			return {
				year: new Date().getFullYear(),
				version: jpackage.version,
				currentURL: window.location.href
			};
		}
	};

</script>
<style></style>
