import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

import * as firebase from "firebase/app";
import "firebase/auth";
import db from "@/modules/firebase/firebaseInit";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;

  var havePlayer = false;

  if (isAuthenticated) {
  	db.collection("users").doc(firebase.auth().currentUser.uid).onSnapshot(doc => {
  		db.collection("players").doc(doc.data().main).onSnapshot(doc => {        
        havePlayer = doc.data().firstName ? true : false;
  		});
  	});
  }

  if (requiresAuth && !isAuthenticated) {
  	next("/login");
  }
  /*else if (!havePlayer) {
    next("/login");  
  }*/
  else {
  	next();
  }
})

export default router;
