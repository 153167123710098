/* eslint-disable no-console */

import {
	register
} from 'register-service-worker'
import jsonPackage from "@/../package.json";

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.');
      localStorage.setItem('horizon:appVer', jsonPackage.version);
    },
    updated () {
      console.log('New content is available; Refreshing...')

      if (!localStorage.getItem('horizon:appVer') || localStorage.getItem('horizon:appVer') != jsonPackage.version) {
        localStorage.setItem('horizon:lastCheck', new Date());
        
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
